<template>
  <div>
    <v-row class="filer-row">
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <SearchTextField
          v-model="selectedSearch"
          :autofocus="false"
          :searchFunction="onFilter"
        />
      </v-col>
      <v-btn 
        @click="onFilter"
        :loading="loading"
        variant="outlined" 
        color="accent" 
        >
            Check locations
            <v-icon end>mdi-arrow-down-bold</v-icon>
      </v-btn>
    </v-row>
    <v-divider />

    <v-data-table
      :headers="[
        { title: 'Id.', key: 'id' },
        { title: 'Name', key: 'name' },
        { title: 'EVSEs', key: 'evses.length' },
        { title: 'City', key: 'city' },
        { title: 'Last updated', key: 'last_updated' },
        { title: 'Actions', key: 'actions', sortable: false }
      ]"
      class="accent-table rounded-table"
      :items="sentLocations"
      :search="selectedSearch"
      :loading="loading"  
      disable-sort
      single-expand
      show-expand
      density="compact"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-2 mt-2 mb-2"
          color="accent"
          @click="updateLocation(item)"
        >Update</v-btn>

        <v-btn
          class="mr-2"
          color="accent"
          :disabled="item.removed"
          @click="deleteLocation(item)"
        >Delete</v-btn>
      </template>
      <template v-slot:item.name="{ item }">
            <LocationName
                :location="item"
                extra="no-arrow"
                type="table"
            />
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <td :colspan="columns.length" class="pa-0">
          <json-viewer
            class="pa-2"
            :value="item"
            :copyable="{
              copyText: 'Copiar',
              copiedText: 'Copiado!',
              timeout: 2000,
            }"
            :expand-depth="3"
          >
          </json-viewer>
        </td>
      </template> 
      <template v-slot:footer.prepend v-if="notSentLocations.length > 0">
        <v-btn
          class="ma-4"
          variant="text"          
          size="small"
          @click="sendLocations(notSentLocations)"
        >
            <v-icon start size="large">mdi-magnify</v-icon> 
            Not sent Locations
        </v-btn>
        <v-spacer></v-spacer>
      </template>
    </v-data-table>
  
    <NotSentLocationsDialog />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus"
import NotSentLocationsDialog from "@/components/provider/NotSentLocationsDialog";
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import LocationName from "@/components/location/LocationName";

export default {
  name: "locations-into-provider",
  components: {
    NotSentLocationsDialog,
    SearchTextField,
    LocationName
  },
  props: ["providerId"],
  inject: ['$api'],
  data() {
    return {
      loading: false,
      provider: null,
      sentLocations: [],
      notSentLocations: [],
      selectedSearch: "",
    };
  },
  watch: {
    sentLocations: function (newValue) {
        if(!newValue) { this.sentLocations = [] }
        this.checkDeletedLocations()
    },
    selectedSearch: _.debounce(function () {
        this.urlPush({ search: this.selectedSearch })
    }, 500),
    //Necessary to update the filters when the user modifies the url query params manually
    '$route.query':{ 
        handler(params) {
            this.routeFilters(params)
        },
        immediate: true
    },
  },
  methods: {
    updateLocations() {
      if (!this.providerId) { return }

      this.loading = true

      this.$api
        .get(`provider/${this.providerId}/emsp-locations`)
        .then((resp) => {
          if (Array.isArray(resp.data)) {
            this.sentLocations = resp.data.filter(location => location.sent).map(location => location.data)            
            this.notSentLocations = resp.data.filter(location => !location.sent).map(location => location.data)                
          }
        })
        .catch(this.showError)
        .then((_) => { this.loading = false })
    },
    updateLocation(item) {
      this.loading = true

      this.$api
        .put(`provider/${this.providerId}/emsp-locations/${item.id}`)
        .then((resp) => {
          if (resp.data) {
            this.sentLocations = this.sentLocations.map(location => { return location.id === resp.data.id ? resp.data : location })
          }
        })
        .catch(this.showError)
        .then((_) => { this.loading = false })
    },
    deleteLocation(item) {
      this.loading = true

      this.$api
        .delete(`provider/${this.providerId}/emsp-locations/${item.id}`, { data: item })
        .then((resp) => {
          if (resp.data) {
            this.sentLocations = this.sentLocations.map(location => { return location.id === resp.data.id ? resp.data : location })
          }
        })
        .catch(this.showError)
        .then((_) => { this.loading = false })
    },
    checkDeletedLocations(){
      this.sentLocations.map(location => {
        const evses = location.evses
        const removedEvses = evses.filter(evse => evse.status === 'REMOVED')
        
        location.removed = evses.length === removedEvses.length

        return location
      })
    },
    sendLocations(locationsToSend) {
      EventBus().emit(
          "dialog-not-sent-locations:open",
          { locations: locationsToSend, providerId: this.providerId }
      )
    },
    onFilter(){
        if(this.loading) return
        this.updateLocations()
    },
  },
};
</script>