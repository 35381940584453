<template>
  <div>
      <!-- Table filters -->
      <v-row class="filer-row">
        <v-col cols="12" md="2">
            <SearchTextField
                v-model="selectedSearch"
                :searchFunction="onFilter"
            />
        </v-col>

        <v-col cols="12" md="2" v-if="!providerId">
          <v-autocomplete
            class="filter-field accent"
            v-model="selectedOwner"
            :items="owners"
            label="Owner"
            clearable
            variant="outlined"
            hide-details
          >
            <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    title
                >
                    <ProviderSelectorTemplate :provider="item.raw" />
                </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="2" v-if="!providerId">
          <v-autocomplete
            class="filter-field accent"
            v-model="selectedApplicableProvider"
            :items="applicableProviders"
            label="Applicable Provider"
            clearable
            variant="outlined"
            hide-details
          >
          <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    title
                >
                    <ProviderSelectorTemplate :provider="item.raw" />
                </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <!-- Checkbox to show tariffs with deleted_at -->
        <v-col cols="12" md="2" xl="1">
          <BooleanSelector
            class="filter-field accent"
            v-model="selectedDeleted"
            label="Deleted"
            trueLabel="Yes"
            falseLabel="No"
            :showIcon = "false"
          />
        </v-col>

        <SearchButton
            :loading="loading"
            :searchFunction="onFilter"
        />

        <v-spacer></v-spacer>

        <v-btn
          class="mr-2"
          :to="'/tariffs/create'"
          color="accent"
          variant="outlined"
          prepend-icon="mdi-plus"
        >
          Create
        </v-btn>

        <div v-if="providerId">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props"
                class="mr-2"
                :loading="sendAllToProvider.loading"
                variant="outlined"
                color="accent"
                @click="sendAllTariffs()"
              >
                <div><span class="text-overline mr-2">Send all</span><v-icon>mdi-send</v-icon></div>
              </v-btn>
            </template>
            <span>Send all tariffs to provider</span>
          </v-tooltip>
        </div>

        <v-btn 
            color="accent"
            :loading="loadingCSV"
            variant="outlined"
            @click="createCSV"
            prepend-icon="mdi-file-download-outline"
        >
            CSV
        </v-btn>
      </v-row>

      <v-data-table
        class="accent-table rounded-table"
        :headers="[
          {key: 'action', sortable:false, cellClass: 'td-linkto', width: '5px'},
          {title: 'Id.', key: 'id'},
          {title: 'Description', key: 'description', sortRaw: sortByDescription},
          {title: 'Owner / Applicable to', key: 'owner_applicable_to', align: 'center', width: '400'},
          {title: 'OCPI Id.', key: 'ocpi_id'},
          {title: 'Name', key: 'name'},
          {title: 'Prices', key: 'price_components', sortRaw: sortByPrice},
          {title: 'Updated at', key: 'updated_at'},
          {title: 'Deleted at', key: 'deleted_at'},
          {title: '', key: 'actions'}
        ]"
        :items="tariffs"
        :items-length="tariffs.length"
        :custom-filter="dataTableDeepSearch"
        :sort-by="[{key:'description', order:'desc'}]"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
        :items-per-page="25"
        density="compact"
      >

        <template v-slot:[`item.action`]="{ item }">
            <v-btn variant="plain" density="compact" size="small" :to="`/tariffs/${item.id}`">
                <v-icon start>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>

        <template v-slot:[`item.owner_applicable_to`]="{ item }">
            <div :class="'d-flex align-center'" style="flex-wrap: wrap; row-gap: 2px;">
                    <!-- Owner -->
                    <v-chip v-if="item.provider_id === 1" class="bg-secondary-chip text-capitalize" variant="flat" size="small"><strong>Wenea</strong></v-chip>
                    <ProviderName
                        v-else
                        :provider="item.provider"
                        role="cpo"
                    />
                    <!-- Arrow -->
                    <v-icon color="secondary">mdi-arrow-right</v-icon>
                    <!-- Apply to -->
                    <v-chip v-if="item.provider_id !== 1" class="bg-accent text-capitalize" variant="flat" size="small"><strong>Wenea</strong></v-chip>
                    <template v-else>
                        <template v-if="item.applicable_providers">
                        <ProviderName
                            :key="applicable_provider" v-for="applicable_provider of item.applicable_providers"
                            class="ml-1"
                            :provider="providers.find(p => p.id == applicable_provider)"
                            extra="applicable_provider"
                            role="emsp"
                        />
                        </template>
                        <template v-else-if="item.applicable_user">
                            <ProviderName
                            :provider="item"
                            extra="applicable_user"
                            role="emsp"
                            />
                        </template>
                        <template v-else>
                            <span class="default-tariff text-uppercase">default<v-icon size="small" class="ml-1" color="black">mdi-tag-outline</v-icon></span>
                        </template>
                    </template>
                </div>
        </template>

        <template v-slot:[`item.description`]="{ item }">
            <template v-if="item.tariff_alt_text && item.tariff_alt_text.a && item.tariff_alt_text.a.length > 0">
                {{ item.tariff_alt_text.a.find(text => text.language == 'EN') ? item.tariff_alt_text.a.find(text => text.language == 'EN').text : item.tariff_alt_text.a[0].text }}
            </template>
        </template>

        <template v-slot:[`item.price_components`]="{ item }">
            <v-row class="my-1" v-for="(element) in item.elements.a">
                <PriceComponentChip v-for="(price_component) in element.price_components" 
                    class="ml-2" 
                    :priceComponent="price_component" 
                    :currency="item.currency" 
                    small="small" 
                />
            </v-row>
        </template>

        <template v-slot:[`item.updated_at`]="{ item }">
            {{ dateFullFormat(item.updated_at)  }}
        </template>

        <template v-slot:[`item.deleted_at`]="{ item }">
            <td class="ml-4 red--text font-weight-medium">
                {{ dateFormat(item.deleted_at)  }}
            </td>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-row>
                <v-spacer></v-spacer>
                <v-tooltip bottom v-if="item.provider.role != 'CPO'">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props"
                            class="mr-2"
                            label="Provider"
                            color="accent"
                            variant="text"
                            @click.stop="sendTariff(item.id, item.applicable_providers, item.applicable_user)"
                        >
                            <v-icon small>mdi-send</v-icon>
                        </v-btn>
                    </template>
                    <span>Send tariff to provider</span>
                </v-tooltip>
            </v-row>
        </template>
      </v-data-table>

      <ImportTariffsFromProvider :providerId="providerId" v-if="hasRole('CPO')" @data-imported="loadTariffs()" class="mt-6"></ImportTariffsFromProvider>
  </div>
</template>

<script>
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import BooleanSelector from "@/components/ui-components/BooleanSelector.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ImportTariffsFromProvider from "@/components/provider/ImportTariffsFromProvider"
import ProviderName from "@/components/provider/ProviderName"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import PriceComponentChip from "@/components/tariff/PriceComponentChip"

export default {
  name: "provider-tariffs",
  props: ["providerId","providerRole"],
  components: {
    SearchTextField,
    BooleanSelector,
    SearchButton,
    ImportTariffsFromProvider,
    ProviderName,
    ProviderSelectorTemplate,
    PriceComponentChip
  },
  inject: ['$api'],
  data() {
    return {
      tariffs: [],
      selectedSearch: null,
      selectedOwner: 1,
      selectedApplicableProvider: null,
      selectedDeleted: false,
      loading: false,
      pagination: {
          itemsPerPageOptions: [25, 50, 100, -1],
      },
      providers: [],
      externalProviders: [],
      sendAllToProvider: {
        loading: false,
        selectedProvider: null
      },
      loadingCSV: false
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    owners() {
      return [{ company: { name: 'Wenea' }, role:'CPO,EMSP', value: 1, title: 'Wenea (CPO,EMSP)' }]
        .concat(this.externalProviders)
    },
    applicableProviders() {
      return [{ company: { name: 'Default' }, value: 'default', title: 'Default' }, { company: { name: 'Wenea' }, role:'CPO,EMSP', value: 'wenea', title: 'Wenea (CPO,EMSP)' }]
        .concat(this.externalProviders)
    }
  },
  watch: {
        //Necessary to update the filters when the user modifies the url query params manually
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
  methods: {
    load() {
        this.$api.get("/providers")
          .then(res => this.providers = res.data)
          .catch(this.showError)

        this.$api.get("/providers-external")
          .then(res => {
            this.externalProviders = res.data
              .map(p => { 
                  return {
                        ...p, 
                        title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                        value: p.id 
                  }
              })
          })
          .catch(this.showError)
      },
      async loadTariffs(){
        this.loading = true

        let apiURL = `/tariffs${this.providerId ? `/applicable_provider/${this.providerId}` : ''}`
        
        apiURL += '?'
        if (this.providerRole) { apiURL += `&role=${this.providerRole}` }
        if (this.selectedOwner) { apiURL += `&owner=${this.selectedOwner}` }
        if (this.selectedDeleted != null) { apiURL += `&deleted=${this.selectedDeleted}` }

        await this.$api.get(apiURL)
            .then(res => {
                if(Array.isArray(res.data)) {
                    this.tariffs = res.data
                }
            })
            .catch(this.showError)
            .finally(() => { this.loading = false })
      },
      async onFilter(){
        if(this.loading) return
        this.urlPush({
            search: this.selectedSearch,
            owner: this.selectedOwner,
            deleted: this.selectedDeleted
        })

        await this.loadTariffs()

        if(this.selectedSearch){
            this.tariffs = this.tariffs.filter(item => {
                return item.ocpi_id.toLowerCase().includes(this.selectedSearch.toLowerCase()) 
                    || item.name?.toLowerCase().includes(this.selectedSearch.toLowerCase())
            })
        }

        if(this.selectedApplicableProvider) {
            switch(this.selectedApplicableProvider){
                case 'wenea':
                    this.tariffs = this.tariffs.filter(tariff => tariff.provider_id != 1)
                    break;
                case 'default':
                    this.tariffs = this.tariffs.filter(tariff => tariff.applicable_providers == null && tariff.applicable_user == null && tariff.provider_id == 1)
                    break;
                default:
                    this.tariffs = this.tariffs.filter(tariff => tariff.applicable_providers?.includes(this.selectedApplicableProvider))
                    break;
            }
        }        
      },
      sendTariff(tariffId){
        const data = {}
        if (this.providerId) {
          data.provider_id = this.providerId
        }

        this.$api.put(`send-tariffs/${tariffId}`, data)
          .then(() => this.showSuccess('Tariff has been sent successfully'))
          .catch(this.showError)
      },
      sendAllTariffs(){
          this.sendAllToProvider.loading = true

          this.$api.put(`emsp/providers/${this.providerId}/send-tariffs`)
            .then(() => this.showSuccess('Tariffs has been sent successfully'))
            .catch(this.showError)
            .then(() => this.sendAllToProvider.loading = false )
      },
      hasRole(role){
        return this.providerRole && this.providerRole.includes(role)
      },
      async createCSV(){
          this.loadingCSV = true

          let content = '"Id";"OCPI Id";"Owner";"Applicable to";"Currency";"Description";"Price components";"Updated at";"Deleted at";\r\n'
          
          for( const row of this.tariffs ){

            const owner = row.provider_id === 1 ? 'Wenea' :  `${ row.provider.country_code }-${ row.provider.party_id }`

            let applicable_to = row.provider_id === 1 ? 'DEFAULT' : 'Wenea'
            if (row.applicable_providers) { 
              applicable_to = this.providers
                .filter( p => row.applicable_providers.includes(p.id) )
                .map(p => p.country_code + '-' + p.party_id)
            }
            if (row.applicable_user) { applicable_to = row.username }

            let description = ""
            if (row.tariff_alt_text && row.tariff_alt_text.a && row.tariff_alt_text.a.length > 0) {
              description = row.tariff_alt_text.a?.find(text => text.language == 'EN') ? row.tariff_alt_text.a?.find(text => text.language == 'EN').text : row.tariff_alt_text?.a[0].text
            }

            let price_components = ""
            for (const element of row?.elements?.a) {
              for (const price_component of element?.price_components) {
                if (price_components != "") { price_components += " | " }
                
                if(price_component.type=='ENERGY') {
                  price_components += `${price_component.price } ${ row.currency } x ${ price_component.step_size } kWh ${ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}`
                }
                
                if(price_component.type=='FLAT'){
                  price_components += `${ price_component.price } ${ row.currency } ${ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}`
                }
                
                if(price_component.type=='PARKING_TIME') {
                  price_components += `${ price_component.price } ${ row.currency } x ${ price_component.step_size } parking seconds ${ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}`
                }

                if(price_component.type=='TIME') {
                  price_components += `${ price_component.price } ${ row.currency } x ${ price_component.step_size } parking seconds ${ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}`
                }
              }
            }

            content += `"${row.id}";"${row.ocpi_id}";"${owner}";"${applicable_to}";"${row.currency}";"${description}";"${price_components}";"${row.updated_at}";"${row.deleted_at || ''}";\r\n`
          }

          this.downloadBlob(content, `tariffs.csv`, "text/csv")
          this.loadingCSV = false
      },
      sortByDescription(a, b) {
        const getText = (item) => {
            if (item.tariff_alt_text && item.tariff_alt_text.a && item.tariff_alt_text.a.length > 0) {
                const textObj = item.tariff_alt_text.a.find(text => text.language == 'EN')
                return textObj ? textObj.text : item.tariff_alt_text.a[0].text
            }
            return ''
        }

        const textA = getText(a)
        const textB = getText(b)

        if (textA < textB) return -1
        if (textA > textB) return 1
        return 0
       },
       sortByPrice(a, b) {
        const getPrice = (item) => {
            let price = 0
            for (const element of item.elements.a) {
                for (const price_component of element.price_components) {
                    price += price_component.price
                }
            }
            return price
        }

        const priceA = getPrice(a)
        const priceB = getPrice(b)

        if (priceA < priceB) return -1
        if (priceA > priceB) return 1
        return 0
       },
  },
};
</script>

<style scoped>
  :deep(.v-data-table)>.v-data-table__wrapper tbody tr {
    box-shadow: none;
    background-color: transparent !important;
  }

  :deep(.default-tariff){
    font-size: 0.85rem; 
    font-weight: 500;
  }
</style>