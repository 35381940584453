<template>
    <v-container fluid>

        <AppBar title="Real-Time Authorizations" icon="mdi-key-wireless" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />
            </v-col>     

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedSender"
                    :items="providers"
                    label="Sender"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedReceiver"
                    :items="providers"
                    label="Receiver"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    color="accent"
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            class="filter-field accent"
                            v-model="selectedDateFrom"
                            label="Date From"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            variant="outlined"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateFromPicker"
                        @update:modelValue="updateFromPickerSelected"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateToPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    color="accent"
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            class="filter-field accent"
                            v-model="selectedDateTo"
                            label="Date To"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            variant="outlined"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateToPicker"
                        @update:modelValue="updateToPickerSelected"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <SearchButton
                :loading="loading"
                :searchFunction="onFilter"
            />
        </v-row>

        <!-- Data table -->
        <v-data-table-server
            class="accent-table rounded-table"
            :items="RTAs"
            :headers="[
                { title: 'Id.', key: 'id' },
                { title: 'Sender', key: 'sender' },
                { title: 'Receiver', key: 'receiver' },
                { title: 'Location Id.', key: 'location' },
                { title: 'Token UId.', key: 'token_uid' },
                { title: 'Token Contract Id.', key: 'token_contract_id' },
                { title: 'Auth. Ref.', key: 'authorization_reference' },
                { title: 'Created at', key: 'created_at' },
            ]"
            density="compact"
            :loading="loading"
            :items-per-page="tableOptions.itemsPerPage"
            :items-length="pagination.serverItemsLength"
            :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
            @update:options="this.updateTableOptions"
            :sort-by="[{ key: 'created_at', order: 'desc' }]"
        >
            <template v-slot:item.sender="{ item }">
                <ProviderName
                    :provider="{ id: item.sender_provider_id, country_code: item.sender_provider_country, party_id: item.sender_provider_party }"
                    role="cpo"
                />
            </template>
            <template v-slot:item.receiver="{ item }">
                <ProviderName
                    :provider="{ id: item.receiver_provider_id, country_code: item.receiver_provider_country, party_id: item.receiver_provider_party }"
                    role="emsp"
                />
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    v-if="item.location_id"
                    :location="{ id: item.location_id, name: item.location_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.allowed="{ item }">
                <v-icon v-if="item.allowed" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ dateFullFormat(item.created_at) }}
            </template>
        </v-data-table-server>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"

export default {
    name: "real-time-authorizations",
    components: {
        AppBar,
        SearchTextField,
        SearchButton,
        ProviderSelectorTemplate,
        ProviderName,
        LocationName,
    },
    inject: ['$api'],
    data() {
        return {
            loading: false,
            RTAs: [],
            providers: [],

            selectedSearch: null,

            selectedSender: null,
            selectedReceiver: null,

            selectedDateFrom: new Date().toISOString().substr(0, 10),
            selectedDateTo: null,
            selectedDateFromPicker: null,
            selectedDateToPicker: null,
            showDateFromPicker: false,
            showDateToPicker: false,

            pagination: {
                itemsPerPageOptions: [25, 50, 100, -1],
                serverItemsLength: 0
            },
            tableOptions: {
                itemsPerPage: 25,
                page: 1
            },
            initialLoad: true
        };
    },
    mounted() {
        this.load()
    },
    watch: {
        //Necessary to update the filters when the user modifies the url query params manually
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        load(){
            this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data.map(p => { 
                        return { 
                            ...p, 
                            title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                            value: p.id 
                        } 
                    })
                })
                .catch(this.showError)
        },
        async loadRTA(){
            this.loading = true

            let queryParams = `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}` 
            if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
            if (this.selectedReceiver) { queryParams += `&filter[receiver_provider_id]=${this.selectedReceiver}` }
            if (this.selectedSender) { queryParams += `&filter[sender_provider_id]=${this.selectedSender}` }
            if (this.selectedDateFrom) { queryParams += `&start_date=${this.dateFormat(this.selectedDateFrom)} 00:00:00` }
            if (this.selectedDateTo) { queryParams += `&end_date=${this.dateFormat(this.selectedDateTo)} 23:59:59` }

            if (this.selectedSearch && this.selectedSearch !== '') { queryParams += `&orfilter[realtimeauths.id,token_uid,token_contract_id,authorization_reference]=${this.selectedSearch}` }

            await this.$api.get(`/real-time-authorizations?${queryParams}`)
                .then((resp) => {
                    this.RTAs = resp.data
                    this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
                })
                .catch(this.showError)
                .then(() => {
                    this.loading = false
                    this.initialLoad = false
                })
        },
        async onFilter(){
            if(this.loading) return

            this.urlPush({
                search: this.selectedSearch,
                sender: this.selectedSender,
                receiver: this.selectedReceiver
            })

            await this.loadRTA()

        },
        updateTableOptions(args){
            this.tableOptions = args
            if(!this.initialLoad){
                this.loadRTA()
            }
        },
        updateFromPickerSelected() {
            this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
            this.showDateFromPicker = false
        },

        updateToPickerSelected() {
            this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
            this.showDateToPicker = false
        },
    }
};
</script>