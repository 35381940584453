<template>
  <div>  
    <v-row class="filer-row">
      <v-spacer></v-spacer>

      <v-col cols="12" md="2">
        <SearchTextField
            v-model="selectedUid"
            :label="'UID'"
            :searchFunction="onFilter"
        />
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          class="filter-field accent"
          v-model="selectedType"
          :items="[
              { value: 'OTHER', title: 'OTHER' },
              { value: 'RFID', title: 'RFID' },
              { value: 'APP_USER', title: 'APP_USER' },
              { value: 'AD_HOC_USER', title: 'AD_HOC_USER' },
          ]"
          label="Type"
          clearable
          variant="outlined"
          hide-details
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          class="filter-field accent"
          v-model="selectedValid"
          :items="[
              { value: true, title: 'Valid' },
              { value: false, title: 'Invalid' },
          ]"
          label="Valid"
          clearable
          variant="outlined"
          hide-details
        >
          <template v-slot:selection="{ item }">
            <v-icon v-if="item.value" color="success" start>mdi-check-circle</v-icon>
            <v-icon v-else color="error" left>mdi-close-circle</v-icon>
            <span>{{item.title}}</span>
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item
                v-bind="props"
                title
            >
                <v-icon v-if="item.value" color="success" start>mdi-check-circle</v-icon>
                <v-icon v-else color="error" start>mdi-close-circle</v-icon>
                <span>{{item.title}}</span>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="2" xl="2">
        <v-menu
            ref="menu"
            class="filter-field"
            v-model="showDateFromPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"    
          >
            <template v-slot:activator="{ props }">
                <v-text-field
                  v-bind="props"
                  class="filter-field accent"
                  v-model="selectedDateFrom"
                  label="Date from"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  clearable
                  variant="outlined"
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateFromPicker"
                @update:modelValue="updateFromPickerSelected"
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>
       <v-col cols="12" md="2" xl="2">
            <v-menu
                ref="menu"
                class="filter-field"
                v-model="showDateToPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"  
            >
                <template v-slot:activator="{ props }">
                    <v-text-field
                      v-bind="props"
                      class="filter-field accent"
                      v-model="selectedDateTo"
                      label="Date to"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      clearable
                      variant="outlined"
                      hide-details
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedDateToPicker"
                    @update:modelValue="updateToPickerSelected"
                    no-title
                ></v-date-picker>
            </v-menu>
        </v-col>

        <v-btn
          :loading="tableLoading"
          @click="onFilter"
          color="accent"
          variant="text"
        >
          SEARCH
        </v-btn>
    </v-row>
    
    <v-data-table-server
        class="accent-table rounded-table hide-footer-pagination-items-count"
        :headers="[
            { title: 'UID', key: 'uid' },
            { title: 'Type', key: 'type' },
            { title: 'Valid?', key: 'valid' },
            { title: 'Auth id.', key: 'contract_id' },
            { title: 'Issuer', key: 'issuer' },
            { title: 'Updated at', key: 'updated_at' },
        ]"
        :items="ocpiTokens"
        :items-per-page="tableOptions.itemsPerPage"
        :items-length="tablePagination.serverItemsLength"
        :loading="tableLoading"
        :footer-props="{
            'items-per-page-options': tablePagination.itemsPerPageOptions,
            'page-text': ''
        }"
        disable-sort
        :sort-by="[{ key: 'updated_at', order: 'asc' }]"
        @update:options="this.updateTableOptions"
        density="compact"
        :no-data-text="'No data, try to click Search button'"
    >
       
      <template v-slot:item.valid="{ item }">
        <v-icon v-if="item.valid" color="success">mdi-check-circle</v-icon>
        <v-icon v-else color="error">mdi-close-circle</v-icon>
      </template>
      <template v-slot:item.updated_at="{ item }">
          {{ dateFullFormat(item.updated_at) }}
      </template>
    </v-data-table-server>
    
    <v-card
      class="mt-6"
      elevation="0"
      variant="outlined"
    >
      <v-card-title>Import tokens from provider</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="2" xl="1">
            <v-menu
              ref="menu"
              class="filter-field"
              v-model="checkTokens.showDateFromPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
                <template v-slot:activator="{ props }">
                    <v-text-field
                      v-bind="props"
                      class="filter-field"
                      v-model="checkTokens.selectedDateFrom"
                      label="Date from"
                      prepend-inner-icon="mdi-calendar"
                      color="accent"
                      readonly
                      clearable
                      variant="outlined"
                      hide-details
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="checkTokens.selectedDateFromPicker"
                    @update:modelValue="updatecheckTokensFromPickerSelected"
                    no-title
                ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-menu
                ref="menu"
                class="filter-field"
                v-model="checkTokens.showDateToPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ props }">
                    <v-text-field
                      v-bind="props"
                      class="filter-field"
                      v-model="checkTokens.selectedDateTo"
                      label="Date to"
                      prepend-inner-icon="mdi-calendar"
                      color="accent"
                      readonly
                      clearable
                      variant="outlined"
                      hide-details
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="checkTokens.selectedDateToPicker"
                    @update:modelValue="updatecheckTokensToPickerSelected"
                    no-title
                ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.selectedOffset"
              type="number"
              label="Offset"
              color="accent"
              variant="outlined"
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.selectedLimit"
              type="number"
              label="Limit"
              color="accent"
              variant="outlined"
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.maxPages"
              type="number"
              label="Max pages"
              color="accent"
              variant="outlined"
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.countryCode"
              type="string"
              label="Country code"
              color="accent"
              variant="outlined"
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.partyId"
              type="string"
              label="Party id"
              color="accent"
              variant="outlined"
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-btn
              :loading="checkTokens.loading"
              @click="updateOcpiTokens(checkTokens.selectedDateFrom, checkTokens.selectedDateTo, checkTokens.selectedOffset, checkTokens.selectedLimit, checkTokens.maxPages, checkTokens.countryCode, checkTokens.partyId)"
              color="accent"
              variant="outlined"
              block
            >
              Import<v-icon end>mdi-table-arrow-up</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" lg="2" xl="1">
              <v-btn
                variant="text" @click="checkTokens.showReport = !checkTokens.showReport" block
              >
                Last report <v-icon end>{{ checkTokens.showReport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
          </v-col>
        </v-row>
        
        <v-row>
          <transition name="slide-y-transition">
            <div v-if="checkTokens.showReport" class="ml-4 mb-2" style="">
              <v-btn variant="text" icon @click="showReportDialog"><v-icon>mdi-magnify</v-icon></v-btn>
              <v-chip class="ml-2" variant="outlined" color="blue">Updated {{ checkTokens.tokenCollectionReport.updated }}</v-chip>
              <v-chip class="ml-2" variant="outlined" color="green">Created {{ checkTokens.tokenCollectionReport.created }}</v-chip>
              <v-chip class="ml-2" variant="outlined" color="red">Errors {{ checkTokens.tokenCollectionReport.errors }}</v-chip>
              <v-chip class="ml-2" variant="outlined"><v-icon start color="grey">mdi-calendar</v-icon> {{ dateFullFormat(checkTokens.tokenCollectionReport.created_at) }}</v-chip>
              <v-chip class="ml-2" variant="outlined" :color="checkTokens.tokenCollectionReport.success ? 'green' : 'red'">
                Finished <v-icon end :color="checkTokens.tokenCollectionReport.success ? 'green' : 'red'">{{ checkTokens.tokenCollectionReport.success ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
              </v-chip>
            </div>
          </transition>
        </v-row>
      </v-card-text>
    </v-card>   
  
    <JsonDialog />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus"
import Bws from "@/plugins/BackofficeWebsocket.js"
import JsonDialog from "@/components/JsonDialog"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"

export default {
  name: "provider-tokens",
  props: ["providerId"],
  components: {
    JsonDialog,
    SearchTextField
  },
  inject: ['$api'],
  data() {
    return {
      ocpiTokens: [],

      tableLoading: false,
      tablePagination: {
        itemsPerPageOptions: [25, 50, 100, 250],
        serverItemsLength: 0
      },
      tableOptions: {
        itemsPerPage: 25,
        page: 1,
      },

      selectedUid: null,
      selectedType: null,
      selectedValid: null,
      selectedDateFrom: null,
      showDateFromPicker: false,
      selectedDateTo: null,
      showDateToPicker: false,
      selectedDateFromPicker: null,
      selectedDateToPicker: null,

      checkTokens: {
        loading: false,
        selectedDateFrom: new Date().toISOString().substr(0, 10),
        showDateFromPicker: false,
        selectedDateTo: null,
        showDateToPicker: false,
        selectedDateToPicker: null,
        selectedDateFromPicker: null,
        selectedOffset: null,
        showReport: false,
        tokenCollectionReport: null,
        selectedLimit: 10,
        maxPages: null,
        countryCode: null,
        partyId: null
      }
    };
  },
  watch: {
    selectedDateFrom: function (newValue) {
      if(!newValue) { this.selectedDateFrom = undefined }
    },
    selectedDateTo: function (newValue) {
      if(!newValue) { this.selectedDateTo = undefined }
    },
    //Necessary to update the filters when the user modifies the url query params manually
    '$route.query':{ 
        handler(params) {
            this.routeFilters(params)
        },
        immediate: true
    },
  },
  mounted() {
    this.getLastTokenCollectionReport()

    // Nos suscribimos a Redis para nuevos reportes
    this.reportsChannel = Bws.getSubscription(`token-import:token_collection:provider-${this.providerId}`)
    
    this.reportsChannel.on('message', (data) => {
      this.checkTokens.tokenCollectionReport = data
      this.checkTokens.loading = false
      this.checkTokens.showReport = true
      
      if (data.errorMessage) {
        return this.showError(data.errorMessage)
      }
      
      this.showSuccess('A new report of a token import process has been received.')
      this.getOcpiTokens()
    })
  },
  methods: {
    updateTableOptions(args){
      // This check is added to not load table automatically when starting component
      if ( this.tableOptions.itemsPerPage == args.itemsPerPage && this.tableOptions.page == args.page ) { return }
      this.tableOptions = args
      this.getOcpiTokens()
    },
    getOcpiTokens() {
      this.tableLoading = true
      
      let queryParams = ''
      queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}`
      if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
      if (this.selectedDateFrom) { queryParams += `&date_from=${new Date(this.selectedDateFrom).toISOString()}` }
      if (this.selectedDateTo) { queryParams += `&date_to=${new Date(this.selectedDateTo).toISOString()}` }
      if (this.selectedUid) { queryParams += `&uid=${this.selectedUid}` }
      if (this.selectedType) { queryParams += `&type=${this.selectedType}` }
      if (this.selectedValid !== null ) { queryParams += `&valid=${this.selectedValid}` }

      this.urlPush({
            dateTo: this.selectedDateTo,
            dateFrom: this.selectedDateFrom,
            uid: this.selectedUid,
            type: this.selectedType,
            valid: this.selectedValid
        })

      this.$api
          .get(`provider/${this.providerId}/paginated-ocpi-tokens?${queryParams}`)
          .then((resp) => {
            this.ocpiTokens = resp.data
            this.tablePagination.serverItemsLength = Number(resp.headers['x-total-count'])
          })
          .catch(this.showError)
          .then(_ => { this.tableLoading = false })
    },
    getLastTokenCollectionReport(){
      this.$api.get(`/provider/${this.providerId}/reports/token_collection`)
        .then(res => this.checkTokens.tokenCollectionReport = res.data)
        .catch(this.showError)
    },
    updateOcpiTokens(date_from, date_to, offset, limit, maxPages, countryCode, partyId) {
      const queryParams = {
        provider_id: this.providerId,
        offset: offset ? offset : 0,
        limit: limit && limit > 0 ? limit : 10
      }

      if (date_from) { queryParams.date_from = date_from }
      if (date_to) { queryParams.date_to= date_to }
      if (maxPages) { queryParams.maxPages = maxPages }
      if (countryCode) { queryParams.countryCode = countryCode }
      if (partyId) { queryParams.partyId= partyId }

      this.checkTokens.loading = true
      this.reportsChannel.emit('import', queryParams)
    },
    showReportDialog(){
      EventBus().emit(
          "dialog-json:open",
          { title: 'Token collection report', json: this.checkTokens.tokenCollectionReport }
      )
    },
    onFilter(){
        if(this.tableLoading) return
        this.getOcpiTokens()
    },
    updateFromPickerSelected() {
        this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
        this.showDateFromPicker = false
    },
    updateToPickerSelected() {
        this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
        this.showDateToPicker = false
    },
    updatecheckTokensFromPickerSelected() {
        this.checkTokens.selectedDateFrom = this.dateFormat(this.checkTokens.selectedDateFromPicker.toISOString())
        this.checkTokens.showDateFromPicker = false
    },
    updatecheckTokensToPickerSelected() {
        this.checkTokens.selectedDateTo = this.dateFormat(this.checkTokens.selectedDateToPicker.toISOString())
        this.checkTokens.showDateToPicker = false
    },
  },
};
</script>