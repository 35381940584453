 <template>
    <div>
        <v-row class="filer-row mt-3">
            
            <v-spacer />

            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />
            </v-col>

            <SearchButton
                :loading="commandsLoading"
                :searchFunction="onFilter"
            />
        </v-row>

        <!-- Data table -->
        <v-data-table
            :headers="[
                { key: 'action', sortable: false, width: '5px' },
                { title: 'Date', key: 'created_at' },
                { title: 'Command', key: 'command' },
                { title: 'Provider', key: 'provider' },
                { title: 'Request', key: 'req_success' },
                { title: 'Response', key: 'resp_success' },
                { title: 'Result', key: 'res_success' },
            ]"
            :items="commands"
            :search="tableSearch"
            disable-sort
            :loading="commandsLoading"
            loading-text="Getting data..."
            density="compact"
            class="accent-table rounded-table"       
        >
            <template v-slot:item.action="{ item }">
                <v-btn
                    variant="plain"
                    icon
                    size="small"
                    :to="`/command/${item.command}/${item.command_id}`"
                >
                    <v-icon>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.command="{ item }">
                {{ item.command }}
                <v-chip class="ml-1 text-chip text-uppercase px-2" size="small" label variant="outlined">
                    # {{ item.command_id }}
                </v-chip>
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.provider_id, country_code: item.provider_country, party_id: item.provider_party }"
                />
            </template>
            <template v-slot:item.req_success="{ item }">
                <v-icon v-if="item.req_success" color="success"
                    >mdi-check-circle</v-icon
                >
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.resp_success="{ item }">
                <v-icon v-if="item.resp_success" color="success"
                    >mdi-check-circle</v-icon
                >
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.res_success="{ item }">
                <v-icon v-if="item.res_success" color="success"
                    >mdi-check-circle</v-icon
                >
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ dateFullFormat(item.created_at) }}
            </template>
        </v-data-table>
    </div>
</template>
<script>

import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderName from "@/components/provider/ProviderName"

export default {
    name: "provider-commands",
    components: {
        SearchTextField,
        SearchButton,
        ProviderName
    },
    props: ["providerId", "role"],
    inject: ['$api'],
    data() {
        return {
            selectedSearch: null,
            commands: [],
            commandsLoading: false,
            tableSearch: null,
        };
    },
    mounted() {
        this.load()
    },
    watch: {
        //Necessary to update the filters when the user modifies the url query params manually
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        load() {
            this.commandsLoading = true;
            this.$api
                .get(`provider/${this.providerId}/commands/${this.role}`)
                .then((resp) => this.commands = resp.data)
                .catch(this.showError)
                .then((_) => {
                    this.commandsLoading = false
                });
        },
        onFilter(){
            if(this.commandsLoading) return
            this.tableSearch = this.selectedSearch
            this.urlPush({ search: this.selectedSearch })

            this.load()

        },
    },
};
</script>