<template>
    <v-container fluid>

        <AppBar title="Session errors" icon="mdi-battery-alert-variant-outline" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedSender"
                    :items="providers"
                    label="Sender CPO"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedReceiver"
                    :items="providers"
                    label="Receiver EMSP"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedError"
                    :items="errors"
                    label="Error"
                    variant="outlined"
                    clearable
                    hide-details
                />
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            class="filter-field accent"
                            v-model="selectedDate"
                            label="Date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="props"
                            variant="outlined"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDatePicker"
                        @update:modelValue="datePickerSelected"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <SearchButton
                :loading="loading"
                :searchFunction="onFilter"
            />
        </v-row>

        <v-data-table
            class="accent-table rounded-table"
            :items="sessions"
            :headers="[
                { key: 'action', sortable: false, cellClass: 'td-linkto', width: '5px' },
                { title: 'CPO', key: 'sender_provider_id' },
                { title: 'EMSP', key: 'receiver_provider_id' },
                { title: 'Location ', key: 'location' },
                { title: 'Start', key: 'start_date_time' },
                { title: 'End', key: 'end_date_time' },
                { title: 'kWh', key: 'kwh' },
                { title: 'Error', key: 'error' },
                { key: 'actions', align: 'end' },
            ]" 
            :loading="loading"
            :sort-by="[{ key: 'start_date_time', order: 'desc' }]"
            items-per-page="25"
            density="compact"
        >
            <template v-slot:item.action="{ item }">
                <v-btn variant="plain" density="compact" size="small" slim :to="`/session/${item.id}`">
                    <v-icon start>mdi-arrow-top-right</v-icon>
                    {{ item.id }}
                </v-btn>
            </template>
            <template v-slot:item.sender_provider_id="{ item }">
                <v-chip v-if="item.sender_provider_id === 1" 
                    class="bg-secondary-chip text-capitalize" 
                    variant="flat" 
                    size="small"
                >
                    Wenea
                </v-chip>
                <strong v-else>
                    <ProviderName
                        :provider="providers.find(p => p.id == item.sender_provider_id)"
                        role="cpo"
                    />
                </strong>
            </template>
            <template v-slot:item.receiver_provider_id="{ item }">
                <v-chip v-if="item.receiver_provider_id === 1" 
                    class="bg-secondary-chip text-capitalize" 
                    variant="flat" 
                    size="small"
                >
                    Wenea
                </v-chip>
                <strong v-else>
                    <ProviderName
                        :provider="providers.find(p => p.id == item.receiver_provider_id)"
                        role="emsp"
                    />
                </strong>
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    v-if="item.location_id"
                    :location="{ id: item.location_id, name: item.location_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.start_date_time="{ item }">
                {{ dateFullFormat(item.start_date_time) }}
            </template>
            <template v-slot:item.end_date_time="{ item }">
                {{ dateFullFormat(item.end_date_time) }}
            </template>
            <template v-slot:item.error="{ item }">
                {{ getErrorText(item.error) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    icon="mdi-magnify" variant="text" size="small"
                    @click="showInDialog(item)"
                />
            </template>       
         </v-data-table>

         <JsonDialog />
    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import JsonDialog from "@/components/JsonDialog"
import _ from "lodash"

export default {
    name: "wenea",
    components: {
        AppBar,
        ProviderName,
        LocationName,
        SearchButton,
        ProviderSelectorTemplate,
        JsonDialog
    },
    inject: ['$api'],
    data() {
        return {
            errors: [
                { value: 'cdr_not_generated', title: 'CDR not generated' },
                { value: 'cdr_provider_response_error', title: 'CDR provider response error' },
                { value: 'core_stop_error', title: 'Core stop error' },
            ],
            providers: [],
            sessions: [],
            loading: true,
            selectedSender: undefined,
            selectedReceiver: undefined,
            selectedDate: null,
            selectedDatePicker: null,
            showDatePicker: false,
            selectedError: null
        }
    },
    mounted() {
        this.$api.get("/providers")
            .then(res => {
                this.providers = res.data.map(p => { 
                    const title = `${p.country_code}-${p.party_id} ${p.company?.name || p.user?.company?.name || p.user?.username}`
                    return { 
                        ...p, 
                        title: p.role ? `${title} (${p.role})` : title, 
                        value: p.id 
                    } 
                })
            })
            .catch(this.showError)
    },
    watch: {
        //Necessary to update the filters when the user modifies the url query params manually
        '$route.query': {
            handler(params) {
                if (_.isEmpty(params) && !this.selectedDate) {
                    this.selectedDate = this.dateFormat(new Date().toISOString())
                }
                this.routeFilters(params)
                this.loadSessions()
            },
            immediate: true
        }
    },
    methods: {
        async loadSessions() {
            this.loading = true

            let queryParams = ''
            if (this.selectedSender) { queryParams += `&sender_provider_id=${this.selectedSender}` }
            if (this.selectedReceiver) { queryParams += `&receiver_provider_id=${this.selectedReceiver}` }
            if (this.selectedDate) { queryParams += `&start_date_time=${this.selectedDate}` }
            if (this.selectedError) { queryParams += `&error=${this.selectedError}` }

            await this.$api.get(`session-errors?${queryParams}`)
                .then(resp => this.sessions = resp.data)
                .catch(this.showError)
                .then(_ => this.loading = false)
        },
        onFilter() {
            if (this.loading) return

            this.urlPush({
                sender: this.selectedSender,
                receiver: this.selectedReceiver,
                date: this.selectedDate,
                error: this.selectedError
            })
            
            this.loadSessions()
        },
        datePickerSelected(){
            this.selectedDate = this.dateFormat(this.selectedDatePicker.toISOString())
            this.showDatePicker = false
        },
        async showInDialog(session) {
            const sessionToShow = await this.$api
                .get(`session/${session.id}`)
                .then(resp => resp.data)

            EventBus().emit(
                "dialog-json:open",
                { 
                    title: `Session #${session.id} - ${this.getErrorText(session.error)}`, 
                    json: sessionToShow, 
                    maxWidth: 800 
                }
            )
        },
        getErrorText(errorCode){
            return this.errors.find(e => e.value == errorCode)?.title
        }
    }
}
</script>
