<template>
    <v-container fluid>

        <AppBar icon="mdi-console-line" title="Commands Start" />

        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />
            </v-col>

            <SearchButton
                :loading="commandsLoading"
                :searchFunction="onFilter"
            />

            <v-spacer />

            <v-btn 
                color="accent"
                :loading="loadingCSV"
                variant="outlined" 
                @click="createCSV"
                prepend-icon="mdi-file-download-outline"
            >
                CSV
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table
            :headers="[
                { key: 'action', sortable:false, cellClass: 'td-linkto', width: '5px' },
                {title: 'Date', key: 'created_at'},
                {title: 'Command', key: 'command'},
                {title: 'Provider', key: 'provider'},
                {title: 'Location', key: 'location'},
                {title: 'Chargepoint', key: 'chargepoint'},
                {title: 'Request', key: 'req_success'},
                {title: 'Response', key: 'resp_success'},
                {title: 'Result', key: 'res_success'}
            ]"
            :items="commands"
            disable-sort
            :loading="commandsLoading"
            :search="tableSearch"
            :custom-filter="dataTableDeepSearch"
            loading-text="Getting data..."
            density="compact"
            class="accent-table rounded-table"        
        >
            <template v-slot:item.action="{ item }">
            <v-btn variant="plain" density="compact" size="small"
                :to="`/command/START_SESSION/${item.command_id}`" 
                >
                <v-icon start>mdi-arrow-top-right</v-icon>
            </v-btn>
            </template>
            <template v-slot:item.command="{ item }">
                <v-chip class="ml-1 text-chip text-uppercase px-2" size="small" label variant="outlined">
                    # {{ item.command_id }}
                </v-chip>
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.provider_id, country_code: item.provider_country, party_id: item.provider_party }"
                    role="emsp"
                />
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    v-if="item.name"
                    :location="{ id: item.location_id, name: item.name }"
                    type="table"
                />
            </template>
            <template v-slot:item.chargepoint="{ item }">
                <ChargepointName
                    :chargepoint="{ id: item.chargepoint_id, name: item.reference_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.req_success="{ item }">
                <v-icon v-if="item.req_success" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.resp_success="{ item }">
                <v-icon v-if="item.resp_success" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.res_success="{ item }">
                <v-icon v-if="item.res_success" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ dateFullFormat(item.created_at) }}
            </template>
        </v-data-table>

    </v-container>
</template>
<script>
import AppBar from "@/components/AppBar"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
    name: "wenea",
    components: {
        AppBar,
        SearchTextField,
        SearchButton,
        ProviderName,
        LocationName,
        ChargepointName
    },
    inject: ['$api'],
    data() {
        return {
            selectedSearch: undefined,
            commands: [],
            commandsLoading: false,
            loadingCSV: false,
            tableSearch: null,
        }
    },
    watch: {
        //Necessary to update the filters when the user modifies the url query params manually
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        async load(){
            this.commandsLoading = true

            await this.$api.get('commands-start')
                .then(resp => this.commands = resp.data)
                .catch(this.showError)
                .then(_ => { 
                    this.commandsLoading = false
                })
        },
        async onFilter(){
            if(this.commandsLoading) return
            this.urlPush({ search: this.selectedSearch })

            await this.load()

            this.tableSearch = this.selectedSearch

        },
        async createCSV(){
            this.loadingCSV = true

            let content = '"Id","Date","Command","Provider","Request","Response","Result"\r\n'
            
            for( const row of this.commands ){
                content += `"${row.command_id}","${row.created_at}","START_SESSION","${row.provider_country}-${row.provider_party}","${row.req_success}","${row.resp_success}","${row.res_success}"\r\n`
            }

            this.downloadBlob(content, `commands_start.csv`, "text/csv")
            this.loadingCSV = false
        }
    }
}
</script>