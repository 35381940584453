<template>
    <v-container fluid class="pa-2 pa-md-4">

        <AppBar title="Users Audit Logs" icon="mdi-tune-vertical" />

        <v-row class="filer-row">

            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="filter.user_id"
                    :items="users"
                    item-title="username"
                    item-value="id"
                    label="User"
                    variant="outlined"
                    clearable
                    hide-details
                ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    color="accent"
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            class="filter-field accent"
                            v-model="selectedDateFrom"
                            label="Date From"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            variant="outlined"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateFromPicker"
                        @update:modelValue="updateFromPickerSelected"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateToPicker"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    color="accent"
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            class="filter-field accent"
                            v-model="selectedDateTo"
                            label="Date To"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            variant="outlined"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateToPicker"
                        @update:modelValue="updateToPickerSelected"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <SearchButton
                :loading="loading"
                :searchFunction="onFilter"
            />

        </v-row>
        
        <v-divider></v-divider>

        <v-data-table
            :headers="[
                { title: 'Id.', key: 'id' },
                { title: 'User Id.', key: 'user_id' },
                { title: 'Username', key: 'user.username' },
                { title: 'Method', key: 'method' },
                { title: 'URL', key: 'url'},
                { title: 'Body', key: 'body'},
                { title: 'Date', key: 'created_at'},
            ]"
            :items="items"
            :loading="loading"
            :items-per-page="25"
            :footer-props="{ itemsPerPageOptions:[25, 50, 100, -1] }"
            :search="selectedSearch"
            density="compact"
            class="accent-table rounded-table"
        >
            <template v-slot:item.body="{ item }">
                <json-viewer
                    :value="item.body"
                    :expand-depth="0"
                />
            </template>
        </v-data-table>
        
        

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import { subDays, format } from 'date-fns'

export default {
    components: {
        AppBar,
        SearchTextField,
        SearchButton
    },
    data() {
        return {
            items: [],
            users: [],
            selectedSearch: "",
            selectedDateFrom: subDays(new Date(), 7).toISOString().substr(0, 10),
            selectedDateTo: new Date().toISOString().substr(0, 10),
            selectedDateFromPicker: null,
            selectedDateToPicker: null,
            showDateFromPicker: false,
            showDateToPicker: false,
            filter: {
                date_from: null,
                date_to: null,
                user_id: null,
            },
            loading: false
        };
    },
    inject: ['$api'],
    async mounted() {

        this.$api.get("/admin-users")
            .then(res => {
                this.users = res.data.map(user => {
                    return {
                        id: user.id,
                        username: user.username,
                    }
                })
            })
            .catch(this.showError)

        this.load()
    },
    watch: {
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
        selectedDate(date){
            this.selectedDateFromPicker = new Date(date)
        }
    },
    methods: {
        async load() {
            this.loading = true

            let queryParams = ''
            if (this.filter.user_id) { queryParams += `&user_id=${this.filter.user_id}` }
            if (this.selectedDateFrom) { queryParams += `&start_date=${this.dateFormat(this.selectedDateFrom)} 00:00:00` }
            if (this.selectedDateTo) { queryParams += `&end_date=${this.dateFormat(this.selectedDateTo)} 23:59:59` }

            this.items = await this.$api
                .get(`/user-actions?${queryParams}`)
                .then(res => res.data)
                .catch(this.showError)

            this.loading = false

        },
        async onFilter(){
            if(this.loading) return

            this.urlPush({
                dateFrom: this.selectedDateFrom,
                dateTo: this.selectedDateTo                
            })

            await this.load()

        },
        updateFromPickerSelected() {
            this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
            this.showDateFromPicker = false
        },

        updateToPickerSelected() {
            this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
            this.showDateToPicker = false
        },
    }
};
</script>